/**
 * Axios
 */
import axios from "axios";

/**
 * @login
 */

export const login = (schema) => {
    const data = {
        password: schema.password,
    };

    data.username = schema.username;
    return new Promise((resolve, reject) => {
        axios.post("/admin/login", data).then(
            (res) => {
                const token = res.data.token;
                localStorage.setItem("temp-admin-token", token);
                localStorage.setItem("admin-username", data.username);
                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};

export const logout = () => {
    localStorage.setItem("admin-token", "");
    localStorage.setItem("temp-admin-token", "");
    localStorage.setItem("admin-username", "");
    localStorage.setItem("user-token", "");
    location.reload();
};

/**
 * @generateRestaurationCode
 */

export const generateRestaurationCode = (schema) => {
    const data = {
        email: schema.email,
    };

    return new Promise((resolve, reject) => {
        axios.post("/admin/generate-email-restoration-code", data).then(
            (res) => {

                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};
/**
 * @verifyDoubleAuthentificationCode
 */

export const verifyDoubleAuthentificationCode = (schema) => {
    const data = {
        token: localStorage.getItem("temp-admin-token"),
        code: schema.code,
    };

    return new Promise((resolve, reject) => {
        axios.post("/system/activatedoubleauthtoken", data).then(
            (res) => {
                localStorage.setItem("temp-admin-token", '');
                // localStorage.setItem("admin-username", '');
                localStorage.setItem("admin-token", data.token);

                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};
/**
 * @resendDoubleAuthentificationCode
 */

export const resendDoubleAuthentificationCode = () => {
    const data = {
        token: localStorage.getItem("temp-admin-token"),
    };

    return new Promise((resolve, reject) => {
        axios.post("/system/resenddoubleauthcode", data).then(
            (res) => {


                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};
/**
 * @verifyRestaurationCode
 */

export const verifyRestaurationCode = (schema) => {
    const data = {
        email: schema.email,
        code: schema.code,
    };

    return new Promise((resolve, reject) => {
        axios.post("/admin/verify-restoration-code", data).then(
            (res) => {

                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};

/**
 * @activateUserAccount
 */

export const activateUserAccount = (schema) => {
    const data = {
        email: schema.email,
        code: schema.code,
    };

    return new Promise((resolve, reject) => {
        axios.post("/admin/activate-account", data).then(
            (res) => {

                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};
/**
 * @restoreNewPassword
 */

export const restoreNewPassword = (schema) => {
    const data = {
        email: schema.email,
        code: schema.code,
        password: schema.password,
    };

    return new Promise((resolve, reject) => {
        axios.post("/admin/restore-password", data).then(
            (res) => {

                resolve(res.data);
            },
            (error) => {
                reject(error);
            }
        );
    });
};

/**
 * @User
 * ***************************************
 */

/**
 * @post
 */
export const createUser = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/admin/register`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateUser = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/admin/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateProfile = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/admin/profile`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @delete
 */
export const deleteUser = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/admin/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};



/**
 * @Workspace
 * ***************************************
 */

/**
 * @post
 */
export const createWorkspace = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/workspace`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateWorkspace = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/workspace/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @delete
 */
export const deleteWorkspace = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/workspace/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @Member
 * ***************************************
 */

/**
 * @inviteMember
 */
export const inviteMember = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/workspace/invite-member`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @acceptToJoin
 */
export const acceptToJoinWorkspace = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/workspace/member/${data.id}/accept-to-join`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @rejectInvitation
 */
export const rejectInvitation = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/workspace/member/${data.id}/reject-invitation`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @cancelInvitation
 */
export const cancelInvitation = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/workspace/member/${data.id}/cancel-invitation`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @updateMemberRole
 */
export const updateMemberRole = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/workspace/member/${data.id}/update-role`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @deleteMemberFromWorkspace
 */
export const deleteMemberFromWorkspace = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/workspace/member/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};



/**
 * @Project
 * ***************************************
 */

/**
 * @post
 */
export const createProject = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateProject = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/project/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @delete
 */
export const deleteProject = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/project/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const buildBackend = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/${data.id}/build-backend`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @post
 */
export const buildErdProject = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/${data.id}/build-erd`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @post
 */
export const buildClassDiagramProject = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/${data.id}/build-class-diagram?manageType=${data.manageType}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const buildCodeProject = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/${data.id}/build-code`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @post
 */
export const buildFrontend = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/${data.id}/build-frontend`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};


/**
 * @post
 */
export const exportPostmanProject = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/${data.id}/export-postman`).then(
            res => {
                // alert(res.data.data)
                resolve(res.data.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const exportZipProject = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/${data.id}/export-zip?obfuscateCode=${data.obfuscateCode}&exportCodeType=${data.exportCodeType}`).then(
            res => {
                // alert(res.data.data)
                resolve(res.data.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const pushProjectToGitLab = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/${data.id}/push-gitlab`).then(
            res => {
                // alert(res.data.data)
                resolve(res.data.data);
            },
            error => {
                reject(error);
            }
        );
    });
};


/**
 * @Model
 * ***************************************
 */

/**
 * @post
 */
export const createModel = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/model`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateModel = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/project/model/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @delete
 */
export const deleteModel = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/project/model/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @save
 */
export const saveModels = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/${data.id}/model/save`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};



/**
 * @Attribute
 * ***************************************
 */

/**
 * @post
 */
export const createAttribute = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/attribute`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateAttribute = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/project/attribute/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @delete
 */
export const deleteAttribute = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/project/attribute/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @post
 */
export const manageClass = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/manage-class`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const manageClassMethod = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/manage-class-method`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @delete
 */
export const deleteClassMethod = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/project/class-method/${data.id}?classMethodType=${data.classMethodType}`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};


/**
 * @save
 */
export const saveClasses = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/${data.id}/class/save`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @feature
 */
export const addFeature = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/project/add-feature`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};



/**
 * @MarchineServer
 * ***************************************
 */

/**
 * @post
 */
export const createMarchineServer = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserver`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateMarchineServer = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/marchineserver/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @delete
 */
export const deleteMarchineServer = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/marchineserver/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @post
 */
export const verifyMarchineServerConnection = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserver/${data.id}/verifyconnection`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};





/**
 * @MarchineServerApp
 * ***************************************
 */

/**
 * @post
 */
export const createMarchineServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const connectionToServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/terminal`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @update
 */
export const updateMarchineServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/marchineserverapp/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @delete
 */
export const deleteMarchineServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/marchineserverapp/${data.id}`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const updateMarchineServerAppGitAccessToken = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/marchineserverapp/${data.id}/git-access-token`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const installRequiredModuleInServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/install-required-module`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const gitCloneServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/git-clone`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};


/**
 * @post
 */
export const gitPullServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/git-pull`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const gitCheckoutServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/git-checkout`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const gitFetchServerAppBranch = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/git-fetch-branch`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const npmInstallServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/npm-install`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const startAppServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/start-app`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const restartAppServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/restart-app`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const stopAppServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/stop-app`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const pushEnFileAppServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/push-envfile`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @post
 */
export const regenerateNginxConfAppServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/regenerate-nginxconf`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @post
 */
export const pushNginxConfAppServerApp = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/push-nginxconf`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const checkNginxConfiguration = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/check-nginxconf`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const restartNginxConfiguration = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/restart-nginxconf`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const uploadSslCertificate = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/sslcertificate`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @post
 */
export const uploadSslCertificateKey = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/sslcertificatekey`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const uploadClientSslCertificate = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/marchineserverapp/${data.id}/sslclientcertificate`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};



/**
 * @BackendTest
 * ***************************************
 */

/**
 * @post
 */
export const createBackendTest = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/backendtest`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateBackendTest = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/backendtest/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @delete
 */
export const deleteBackendTest = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/backendtest/${data.id}`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @post
 */
export const simulateBackendTest = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/backendtest/${data.id}/simulate`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};


/**
 * @BackendTestScenario
 * ***************************************
 */

/**
 * @post
 */
export const createBackendTestScenario = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/backendtestscenario`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @update
 */
export const updateBackendTestScenario = async(data) => {
    return new Promise((resolve, reject) => {
        axios.put(`/backendtestscenario/${data.id}`, data.schema).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @delete
 */
export const deleteBackendTestScenario = async(data) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/backendtestscenario/${data.id}`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @post
 */
export const simulateBackendTestScenario = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/backendtestscenario/${data.id}/simulate`).then(
            res => {
                resolve(res.data);
            },
            error => {
                reject(error);
            }
        );
    });
};







/**
 * @post
 */
export const exportBackendTestPostman = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/backendtest/${data.id}/export-postman`, data.schema).then(
            res => {
                // alert(res.data.data)
                resolve(res.data.data);
            },
            error => {
                reject(error);
            }
        );
    });
};



/**
 * @ShopingCart
 * ***************************************
 */

/**
 * @post
 */
export const addToShopingCart = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/shopingcart/adddetail`, data.schema).then(
            res => {
                // alert(res.data.data)
                resolve(res.data.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @ShopingCart
 * ***************************************
 */

/**
 * @post
 */
export const removeFromCart = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/shopingcart/removedetail/${data.id}`).then(
            res => {
                // alert(res.data.data)
                resolve(res.data.data);
            },
            error => {
                reject(error);
            }
        );
    });
};
/**
 * @Transaction
 * ***************************************
 */

/**
 * @post
 */
export const createTransaction = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/transaction`, data.schema).then(
            res => {
                // alert(JSON.stringify(res.data.data))
                resolve(res.data.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const transactionFeedback = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/transaction/${data.id}/feedback`, data.schema).then(
            res => {
                // alert(JSON.stringify(res.data.data))
                resolve(res.data.data);
            },
            error => {
                reject(error);
            }
        );
    });
};

/**
 * @post
 */
export const cardTransactionFeedback = async(data) => {
    return new Promise((resolve, reject) => {
        axios.post(`/transaction/${data.id}/stripe-card-feedback`, data.schema).then(
            res => {
                // alert(JSON.stringify(res.data.data))
                resolve(res.data.data);
            },
            error => {
                reject(error);
            }
        );
    });
};